<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Dropdown</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A dropdown is an interactive pattern to limit the users selections
              to a pre-defined list.
            </p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h2>General Guidelines</h2>
            <ul class="bullet-red">
              <li>
                To maximize usability a dropdown should only in rare cases be
                used with more than 15 options.
              </li>
              <li>
                A dropdown can have more than 15 options, if all the options
                would be apparent to the user. For example, a list of countries,
                states or counties.
              </li>
              <li>
                A dropdown should never be used for multiple selections. Use a
                multiple-select pattern instead.
              </li>
              <li>
                A dropdown should never navigate the user to a new page or used
                like a tab toggle to show/hide a section.
              </li>
              <li>
                Each option should be no more than 3 words. If you need to more
                than 3 words to communicate an option a different selection
                pattern should be explored (example radio button)
              </li>
            </ul>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h3>Dropdown</h3>
            <CodeView :component="primary.main">
              <DropdownComponent />
            </CodeView>
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
// import StickyTOC from "../StickyTOC";
import DropdownComponent from "../DropdownComponent";
import CodeView from "../CodeView";
import TempLeftNav from "../TempLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Dropdown",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML:
            "<button id='button-js--primary' class='button button--primary'>Button</button>",
          codeCSS: `           .button { 
                      padding: 12px 25px; 
                      width: 140px; 
                      color: white; 
                      background-color: #1a5192; 
                      text-align: center; 
                      border-radius: 20px; 
                      border: none; 
                      font-size: 1rem; 
                      cursor: pointer;
                    }`,
          codeJS:
            "document.getElementById('button-js--primary').addEventListener('click', ()=> {console.log('Hello, world!')});",
          preview: "This is the preview",
          name: "Dropdown Field and Label",
        },
      },
    };
  },
  components: {
    CodeView,
    DropdownComponent,
    TempLeftNav,
    SubFooter,
    // StickyTOC,
  },
};
</script>
