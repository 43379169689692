<template>
  <div class="select__container">
    <select name="select" id="select"
      ><option value="option-1">Option 1</option
      ><option value="option-2">Option 2</option></select
    >
    <div class="fake-select" @click="isOpen = !isOpen">
      {{
        selectedValue.length > 0
          ? selectedValue.replaceAll("-", " ")
          : "Select an option"
      }}
      <svg
        v-if="!isOpen"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-down"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-up"
      >
        <polyline points="18 15 12 9 6 15"></polyline>
      </svg>
    </div>
    <div v-if="isOpen">
      <div class="fake-select__option" @click="handleValueSelect('option-1')">
        option 1
      </div>
      <div class="fake-select__option" @click="handleValueSelect('option-2')">
        option 2
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownComponent",
  data: function() {
    return {
      isOpen: false,
      selectedValue: "",
    };
  },
  methods: {
    handleValueSelect: function(value) {
      this.selectedValue = value;
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
select {
  display: none;
}
.select__container {
  width: 40%;
  margin: 0 auto;
}
.fake-select {
  width: 280px;
  height: 50px;
  background-color: #f5f5f5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 2px solid #8c8c8c;
  padding: 10px;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
}
.fake-select__option {
  background-color: #f5f5f5;
  width: 280px;
  cursor: pointer;
  transition: ease 0.3s;
  padding: 10px;
}
.fake-select__option:hover {
  background-color: #bfbfbf;
}
.feather {
  position: absolute;
  right: 15px;
}
</style>
